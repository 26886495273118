import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 453.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,453.000000) scale(0.100000,-0.100000)">


<path d="M2880 3326 l0 -74 443 -4 442 -4 75 -27 c41 -15 77 -32 78 -37 2 -6
10 -10 18 -10 7 0 43 -29 79 -64 48 -47 75 -85 103 -143 36 -77 37 -80 37
-198 -1 -109 -3 -126 -29 -187 -15 -38 -32 -68 -37 -68 -5 0 -9 -7 -9 -15 0
-40 -207 -195 -261 -195 -8 0 -20 -4 -28 -9 -14 -9 -22 9 164 -396 48 -104 94
-205 101 -222 l14 -33 -90 0 c-90 0 -91 0 -101 28 -5 15 -69 158 -143 317
l-133 290 -201 3 -202 2 0 -320 0 -320 -160 0 -160 0 0 -80 0 -80 240 0 240 0
0 320 0 320 69 0 68 0 93 -202 c52 -112 117 -255 146 -318 l53 -115 266 -3
265 -2 -11 26 c-5 15 -16 39 -24 53 -7 14 -59 125 -114 246 -54 121 -117 259
-139 307 l-40 86 41 28 c139 92 242 245 278 415 16 74 6 239 -20 319 -38 122
-131 247 -237 321 -27 19 -51 36 -54 40 -10 11 -110 49 -166 64 -48 12 -139
15 -506 15 l-448 0 0 -74z"/>
<path d="M2880 2440 l0 -640 80 0 80 0 0 560 0 560 343 0 c390 0 392 0 434
-80 36 -67 28 -137 -20 -188 -45 -48 -72 -52 -343 -52 l-254 0 0 -81 0 -80
278 3 c275 3 277 3 332 29 65 31 131 95 162 158 32 65 32 195 1 263 -29 63
-97 129 -166 161 l-56 27 -435 0 -436 0 0 -640z"/>
<path d="M1494 1121 c-2 -2 -4 -85 -4 -183 l0 -178 30 0 30 0 0 65 0 65 33 0
c28 0 37 -7 76 -65 40 -58 48 -65 78 -65 18 0 33 3 33 8 0 8 -29 53 -40 62 -3
3 -16 19 -28 37 l-22 32 29 17 c59 35 69 113 22 166 -29 32 -31 33 -131 38
-56 3 -104 3 -106 1z m191 -80 c32 -54 -1 -91 -80 -91 l-55 0 0 53 c0 30 3 57
7 61 13 13 117 -6 128 -23z"/>
<path d="M2263 1122 c-23 -3 -23 -5 -23 -183 l0 -179 30 0 30 0 0 75 0 75 80
0 80 0 0 -75 0 -75 30 0 30 0 0 180 0 180 -30 0 -29 0 -3 -72 -3 -73 -75 0
-75 0 -3 70 c-2 39 -6 72 -10 76 -4 3 -17 3 -29 1z"/>
<path d="M4785 1118 c-46 -25 -65 -51 -65 -89 0 -50 31 -83 96 -103 72 -23 88
-37 80 -69 -10 -43 -76 -47 -163 -11 -9 4 -13 -4 -13 -25 0 -25 6 -34 28 -42
113 -44 212 -4 212 85 0 59 -20 80 -101 111 -80 31 -94 49 -58 78 23 19 75 20
122 1 14 -5 17 0 17 24 0 27 -5 32 -37 41 -48 14 -93 13 -118 -1z"/>
<path d="M5052 1122 c-8 -2 -12 -38 -12 -121 0 -101 3 -123 23 -161 37 -74
124 -101 204 -63 62 29 73 60 73 211 l0 132 -30 0 -30 0 0 -121 c0 -156 -11
-179 -85 -179 -74 1 -80 12 -83 164 -3 129 -4 134 -26 138 -12 3 -27 3 -34 0z"/>
<path d="M1967 1113 c-3 -5 -24 -56 -47 -113 -23 -58 -47 -118 -54 -135 -45
-105 -45 -107 -4 -103 27 3 33 9 47 46 l15 42 78 0 77 0 20 -45 c17 -39 24
-45 50 -45 17 0 31 4 31 8 0 4 -31 84 -68 177 l-68 170 -36 3 c-20 2 -38 -1
-41 -5z m73 -155 l17 -48 -55 0 c-48 0 -53 2 -47 18 4 9 16 42 27 72 23 61 20
63 58 -42z"/>
<path d="M2716 1094 c-4 -14 -34 -93 -66 -176 -33 -82 -60 -152 -60 -155 0 -2
15 -3 33 -1 28 3 34 9 47 46 l14 42 79 0 78 0 19 -45 c17 -39 23 -45 50 -45
16 0 30 3 30 8 1 4 -30 85 -67 179 l-68 173 -41 0 c-37 0 -43 -3 -48 -26z m82
-126 l20 -58 -48 0 c-27 0 -51 3 -53 8 -7 12 45 124 54 115 4 -4 16 -34 27
-65z"/>
<path d="M3000 939 l0 -182 106 6 c102 5 108 6 137 36 40 40 41 90 2 131 -18
19 -23 30 -15 35 20 13 29 52 20 87 -15 52 -51 68 -159 68 l-91 0 0 -181z
m171 121 c21 -12 25 -50 7 -68 -7 -7 -36 -12 -65 -12 l-53 0 0 38 c0 21 3 42
7 45 10 11 82 8 104 -3z m20 -150 c12 -7 19 -21 19 -41 0 -36 -32 -51 -99 -47
-44 3 -46 4 -49 36 -5 54 2 62 58 62 28 0 60 -5 71 -10z"/>
<path d="M3350 939 l0 -182 88 5 c111 7 155 27 182 83 26 53 27 146 2 195 -9
19 -32 45 -51 57 -29 20 -47 23 -127 23 l-94 0 0 -181z m169 118 c54 -19 77
-109 47 -182 -18 -42 -42 -55 -105 -55 l-51 0 0 125 0 125 38 -1 c20 0 52 -5
71 -12z"/>
<path d="M3809 1093 c-10 -28 -45 -117 -98 -253 -24 -62 -26 -76 -14 -78 25
-6 63 8 63 23 0 8 5 26 12 40 11 24 15 25 89 25 l77 0 14 -42 c13 -37 19 -43
46 -46 21 -2 32 1 32 10 0 7 -30 88 -67 181 l-68 167 -38 0 c-32 0 -39 -4 -48
-27z m75 -105 c10 -29 21 -59 23 -65 4 -10 -9 -13 -47 -13 -37 0 -51 4 -48 13
3 6 13 36 23 65 11 28 22 52 25 52 3 0 14 -24 24 -52z"/>
<path d="M4020 1090 l0 -30 60 0 c43 0 60 -4 59 -12 -1 -7 -1 -75 0 -150 l1
-138 30 0 30 0 0 150 0 150 60 0 60 0 0 30 0 30 -150 0 -150 0 0 -30z"/>
<path d="M4427 1058 c-46 -111 -98 -242 -107 -270 -8 -27 -7 -28 24 -28 30 0
35 4 50 45 l18 45 78 0 78 0 14 -42 c13 -37 19 -43 46 -46 21 -2 32 1 32 10 0
7 -30 88 -67 180 l-67 168 -36 0 c-36 0 -38 -2 -63 -62z m87 -70 c10 -29 21
-59 23 -65 4 -10 -9 -13 -47 -13 -49 0 -51 1 -45 23 12 39 40 107 45 107 3 0
14 -24 24 -52z"/>
<path d="M5440 940 l0 -180 73 0 c97 1 147 14 175 45 31 36 30 88 -3 121 -29
29 -30 31 -10 50 8 9 15 27 15 42 -1 81 -33 102 -159 102 l-91 0 0 -180z m174
114 c38 -37 5 -74 -65 -74 l-49 0 0 45 0 45 49 0 c32 0 55 -6 65 -16z m13
-149 c15 -10 20 -22 16 -37 -10 -41 -23 -48 -84 -48 l-59 0 0 50 0 50 53 0
c30 0 61 -6 74 -15z"/>
<path d="M1815 638 c11 -6 23 -16 27 -22 5 -8 8 -7 8 1 0 7 -9 17 -19 23 -27
14 -41 12 -16 -2z"/>
<path d="M2051 598 c0 -42 2 -48 9 -30 11 27 26 28 42 2 7 -11 17 -20 22 -20
4 0 3 6 -3 14 -7 8 -9 25 -5 41 7 30 -6 45 -43 45 -21 0 -23 -4 -22 -52z m43
36 c25 -10 19 -34 -9 -34 -24 0 -34 18 -18 33 8 8 8 8 27 1z"/>
<path d="M2283 643 c-22 -8 -14 -39 12 -51 33 -15 32 -32 -2 -33 -26 -2 -26
-2 -4 -6 13 -3 27 -1 32 4 14 14 1 40 -23 46 -23 7 -19 33 6 31 9 -1 16 2 16
7 0 10 -17 11 -37 2z"/>
<path d="M2410 641 c0 -5 -3 -18 -6 -28 -5 -15 -4 -16 5 -3 10 13 12 13 21 0
7 -12 4 -16 -13 -19 -22 -3 -37 -18 -37 -36 1 -5 5 -2 11 8 5 9 19 17 29 17
10 0 24 -8 30 -17 9 -15 10 -14 4 7 -13 48 -44 98 -44 71z"/>
<path d="M2512 643 c12 -3 18 -18 21 -51 l3 -47 2 47 c2 35 6 48 20 51 9 3 -1
5 -23 5 -22 0 -32 -2 -23 -5z"/>
<path d="M2633 600 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M2700 635 c-16 -19 -10 -19 26 0 25 13 26 15 8 15 -12 0 -27 -7 -34
-15z"/>
<path d="M2817 637 c-6 -16 -1 -76 7 -84 3 -4 6 3 6 15 0 13 8 22 23 25 l22 4
-22 2 c-35 2 -31 39 5 44 26 4 26 4 -4 6 -18 0 -34 -5 -37 -12z"/>
<path d="M3066 631 c-10 -11 -16 -29 -13 -43 4 -22 4 -21 6 3 1 29 15 44 46
52 19 4 19 5 -1 6 -11 0 -29 -8 -38 -18z"/>
<path d="M3192 643 c12 -3 18 -18 21 -51 l3 -47 2 47 c2 35 6 48 20 51 9 3 -1
5 -23 5 -22 0 -32 -2 -23 -5z"/>
<path d="M3353 638 c21 -7 27 -17 28 -41 l2 -32 4 30 c5 31 -16 56 -46 54 -9
0 -4 -5 12 -11z"/>
<path d="M3451 598 c1 -29 4 -42 6 -30 3 18 10 23 23 19 23 -6 43 16 37 40 -4
14 -5 13 -6 -4 -1 -18 -7 -23 -26 -23 -33 0 -35 37 -2 43 19 4 18 4 -5 6 -27
1 -28 0 -27 -51z"/>
<path d="M3570 647 c0 -5 6 -15 14 -23 8 -7 17 -19 19 -26 4 -10 8 -9 16 2 6
8 11 20 11 25 0 6 -4 3 -9 -4 -8 -12 -13 -11 -30 10 -11 13 -20 20 -21 16z"/>
<path d="M3793 600 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M3864 635 c-7 -18 12 -45 32 -45 26 0 14 -29 -13 -33 -26 -4 -26 -4
5 -6 26 -1 32 3 32 19 0 12 -10 25 -25 32 -27 12 -28 34 -1 32 9 -1 16 2 16 7
0 15 -40 10 -46 -6z"/>
<path d="M4098 643 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4213 605 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M4282 608 c-2 -40 -5 -47 -24 -51 l-23 -4 23 -2 c27 -1 35 15 29 65
-3 38 -4 37 -5 -8z"/>
<path d="M4352 593 l2 -58 3 53 c4 48 6 53 25 50 30 -4 34 -26 7 -36 -18 -7
-19 -10 -6 -15 8 -3 21 1 28 9 18 21 0 48 -35 52 -26 3 -26 3 -24 -55z"/>
<path d="M4576 634 c-17 -16 -20 -41 -10 -68 4 -11 18 -16 43 -14 l36 1 -32 4
c-56 7 -56 75 0 86 l22 4 -22 2 c-12 0 -29 -6 -37 -15z"/>
<path d="M4716 641 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M4842 598 c1 -45 2 -47 5 -15 4 39 4 39 26 20 20 -17 20 -17 -2 15
-12 17 -24 32 -27 32 -2 0 -3 -24 -2 -52z"/>
<path d="M5000 641 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0 -20
-4 -20 -9z"/>
<path d="M5110 600 l0 -50 33 1 c30 2 30 2 5 6 -34 5 -37 30 -5 36 l22 4 -22
2 c-35 2 -31 39 5 44 25 4 25 4 -5 6 l-33 1 0 -50z"/>
<path d="M5232 598 l2 -53 3 47 c3 39 8 47 26 51 18 4 17 5 -5 6 -27 1 -28 0
-26 -51z"/>
<path d="M5362 593 l1 -58 6 50 c5 38 8 45 14 29 4 -11 19 -31 32 -44 l25 -23
-1 49 -2 49 -6 -39 -6 -40 -28 42 c-15 23 -29 42 -32 42 -3 0 -4 -26 -3 -57z"/>
<path d="M5509 634 c-11 -14 -10 -18 6 -30 10 -8 22 -14 27 -14 22 0 4 -29
-19 -33 -26 -4 -26 -4 5 -6 26 -1 32 3 32 19 0 12 -10 25 -25 32 -31 14 -32
26 -2 38 15 6 17 9 6 9 -9 1 -23 -6 -30 -15z"/>
<path d="M1646 625 c4 -8 10 -15 15 -15 5 0 10 -15 10 -32 2 -28 2 -29 6 -8 3
14 10 31 15 38 8 9 7 11 -6 6 -10 -4 -18 0 -22 10 -3 9 -10 16 -15 16 -4 0 -6
-7 -3 -15z"/>
<path d="M1911 613 c-1 -42 8 -63 28 -63 13 0 13 2 2 9 -8 5 -17 26 -21 47 -7
38 -8 38 -9 7z"/>
<path d="M1980 608 c0 -19 -6 -40 -12 -47 -11 -11 -10 -13 2 -9 16 5 32 76 18
84 -4 3 -8 -10 -8 -28z"/>
<path d="M2945 623 c-15 -35 -13 -43 10 -43 13 0 29 -8 35 -17 9 -15 10 -14 4
7 -7 29 -24 51 -24 32 0 -7 -4 -12 -10 -12 -5 0 -10 7 -10 15 0 8 5 15 10 15
6 0 10 5 10 10 0 16 -17 11 -25 -7z"/>
<path d="M4067 633 c-12 -12 -7 -60 7 -72 8 -7 27 -11 43 -9 24 3 28 8 31 37
2 18 0 37 -5 42 -4 4 -8 -9 -8 -29 0 -34 -2 -37 -30 -37 -28 0 -30 2 -30 38 0
20 0 37 -1 37 0 0 -4 -3 -7 -7z"/>
<path d="M4767 608 c8 -44 13 -48 13 -10 0 17 -4 34 -10 37 -6 4 -7 -6 -3 -27z"/>
<path d="M4910 611 c0 -16 -5 -31 -11 -33 -7 -2 -5 -9 5 -17 14 -12 16 -9 16
33 0 25 -2 46 -5 46 -3 0 -5 -13 -5 -29z"/>
<path d="M5291 627 c0 -10 -11 -21 -23 -26 -17 -6 -18 -9 -5 -14 20 -7 40 16
34 40 -4 17 -5 17 -6 0z"/>
<path d="M3300 597 c0 -32 18 -47 55 -46 30 2 30 2 -5 8 -30 5 -35 10 -35 34
1 15 -3 27 -7 27 -4 0 -8 -10 -8 -23z"/>
<path d="M4691 603 c-1 -28 8 -53 21 -53 7 0 7 4 0 13 -5 6 -12 23 -15 37 -4
22 -4 22 -6 3z"/>
<path d="M4981 600 c-1 -33 16 -50 49 -49 23 1 23 2 -5 8 -23 5 -31 13 -36 36
-6 28 -7 28 -8 5z"/>
<path d="M1760 595 c0 -27 11 -41 37 -47 27 -7 58 13 50 33 -4 10 -6 9 -6 -4
-1 -28 -52 -18 -68 13 -11 22 -13 22 -13 5z"/>
<path d="M2715 599 c34 -21 36 -34 7 -41 l-27 -7 29 2 c47 3 44 38 -4 51 -23
6 -23 6 -5 -5z"/>
<path d="M3505 560 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M4400 560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5285 560 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M3083 553 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4748 553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>

        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}